import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import * as styles from "./TermsPage.module.scss"

const TermsPage = () => {
  return (
    <Layout pushTop>
      <Seo title="Terms of service" />

      <div className={styles.pageContent}>
        <Container fluid="xxl">
          <Row>
            <Col md="6">
              <h1 className="text-uppercase mb-5">Terms of service</h1>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">Disclaimer</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                Warning: GritLife and the Content it contains is not intended as
                a substitute for professional consultation with a qualified
                medical practitioner. We ask that before relying on any
                information, opinions or research you may find on GritLife to
                make your own inquiries and seek professional advice from
                medical experts. The content provided in our Service is to be
                seen as general in nature only.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">Introduction</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                Please read these Terms and Conditions (“Terms”, “Terms and
                Conditions”) carefully before using the GritLife application and
                website (the “Service”) operated by GritLife (“us”, “we”, or
                “our”).
              </p>
              <p>
                By using the Service, you (“our user”, “user”) agree to the
                conditions laid out in our Terms of Service.
              </p>
              <p>
                These Terms will apply to all visitors, users, and others who
                access or use the Service through your account or device you
                access our Service through.
              </p>
              <p>
                You warrant that you are at least 18 years of age, and you are
                legally capable of entering into binding contracts. If you are
                under 18 years, you warrant that you have obtained consent from
                your parent/s or guardian, and they agree to be bound by these
                Terms on your behalf.
              </p>
              <p>
                By accessing or using the Service, you agree to be bound by
                these Terms. If you disagree with any part of the terms, then
                you may not access the Service.
              </p>
              <p>
                Your access to the Service is deemed as conditional on your
                acceptance of and compliance with these Terms. At any time, if
                found in breach of these terms, we reserve the right to
                discontinue your access to the Service without notice.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">1. Content</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                1.1 Our Service allows you to access and read information, text,
                graphics, videos, and other material (“Content”) provided by us.
                All Content shared by us as part of the Service to our Users is
                solely owned by us as intellectual property (“IP”). Through the
                usage of our Service any Content you may access you agree to be
                used in noncommercial applications only.
              </p>
              <p>1.2 You represent and warrant, understanding that:</p>
              <p>
                (i) Any content you view or access is the sole IP of GritLife
                and may not be re-distributed, sold or copied from the Service.
                Any breach of this clause will be grounds for legal action.
              </p>
              <p>
                (ii) Any recommendations made through our Content are
                non-medical and, if at any point, you are uncertain with the
                details of the Content provided, it is your responsibility to
                seek advice from a registered medical practitioner.
              </p>
              <p>
                1.3 You agree and understand that as part of the Service, we are
                under no obligations to regularly monitor the accuracy or
                reliability of our Content incorporated into the Service. We
                reserve the right to modify or remove any Content at any time.
              </p>
              <p>
                1.4 Upon creation of an account with us; unless specified at the
                start of the Account creation process, all users will be
                registered in the Service with a Free account (“non-subscriber”,
                “non-paying user”). As a non-subscriber, you will be given
                limited access to the Service. Upon upgrading to a Premium
                account (“subscriber”, “paying user”), you will be given full
                access to the Service.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">
            2. Accounts &amp; Information Retention
          </h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                2.1 When you create an account with us, you must provide us with
                information that is accurate, complete, and current at all
                times. Failure to do so constitutes a breach of the Terms, which
                may result in immediate termination of your account on our
                Service.
              </p>
              <p>
                2.2 You are responsible for safeguarding the password that you
                use to access the Service and for any activities or actions
                under your password, whether your password is with our Service
                or a third-party service.
              </p>
              <p>
                2.3 You agree not to disclose your password to any third party.
                You agree to be fully responsible for activities that relate to
                your account or your password. You must notify us immediately
                upon becoming aware of any breach of security or unauthorized
                use of your account.
              </p>
              <p>
                2.4 The person whose information (e.g. name, email, address or
                phone number) the GritLife account is registered to shall be the
                owner of the account (“account holder”). Accounts access must
                not be shared, and each account holder is responsible for the
                protection of their own account security.
              </p>
              <p>
                2.5 Whilst all reasonable care and security will be applied, no
                responsibility can or will be accepted for any unauthorized
                access to your account by unapproved parties through illegal
                means such as hacking or like acts.
              </p>
              <p>
                2.6 You agree to allow us to retain the Personal and Usage
                Information we collect as part of our Service to you. This
                information will be used by Us for the sole purpose of providing
                the service to you.
              </p>
              <p>
                2.7 Any Personal Information we collect will be held in
                accordance with our{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">
            4. GritLife Premium and Purchases
          </h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                4.1 By joining the GritLife Premium Subscription Service
                (“Premium Service”), the account holder will receive:
              </p>
              <p>Access to featured Coaching and premium content;</p>
              <p>
                Access to activities and programs not available on our Free User
                account; and
              </p>
              <p>
                Additional functionality that is not accessible to non-paying
                users.
              </p>
              <p>
                These additional features are made available to only account
                holders who hold an active subscription to the Premium Service.
              </p>
              <p>
                4.2 Subscription payments will be processed automatically either
                annually or monthly (“billing cycle”) for a calculated period
                from the date of upgrade to the GritLife subscription end date
                (“payment date”) unless cancelled prior to the payment date.
              </p>
              <p>
                4.3 It is the responsibility of the account holder for the
                cancellation, management and payment of their GritLife Premium
                subscription.
              </p>
              <p>
                4.4 Any additional charges, such as overdrawn accounts and or
                exceeding credit card limit, will be the liability of the
                account holder.
              </p>
              <p>
                4.5 Failure to process, charge or pay subscription payments
                prior to the payment date will constitute the automatic
                termination of the Premium Subscription and the subscriber's
                account will be downgraded to a Free account.
              </p>
              <p>
                4.6 Your Premium Service may be cancelled by you at any time.
              </p>
              <p>
                4.7 Your access to the Premium Service will end at the
                conclusion of your current paid billing cycle (month or year)
                regardless of the date of cancellation.
              </p>
              <p>
                4.8 GritLife reserves the right to limit the number of accounts
                that may be charged to a single credit card or other payment or
                identification method per unique user.
              </p>
              <p>
                4.9 GritLife may reject or cancel payments that are deemed to be
                fraudulent or incorrect.
              </p>
              <p>
                4.10 All information that the account holder provides in
                connection with a purchase or transaction or other monetary
                transaction interaction with GritLife must be accurate,
                complete, and current. The account holder agrees to pay all
                charges incurred by users of their credit card, debit card, or
                other payment method used in connection with a purchase or
                transaction or other monetary transaction interaction with
                GritLife at the prices in effect when such charges are incurred.
                Account-holders will pay any applicable sales taxes (e.g. GST),
                if any, relating to any such purchases, transactions or other
                monetary transaction interactions.
              </p>
              <p>
                4.11 Please note that GritLife is unable to process refunds for
                GritLife Premium subscriptions payments once payment has been
                made and the payment date passed. Therefore, it is the
                responsibility of the subscriber to cancel, downgrade or inform
                GritLife of any changes in circumstances prior to the next due
                payment date.
              </p>
              <p>
                4.12 GritLife may increase the Service Fees at any time on or
                after the expiration of the current Term, where applicable.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">5. Links to other websites</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                5.1 Our Service may contain links to third-party websites or
                services that are not owned or controlled by GritLife.
              </p>
              <p>
                5.2 GritLife has no control over and assumes no responsibility
                for, the content, privacy policies, or practices of any third
                party websites or services. You further acknowledge and agree
                that GritLife shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with use of or reliance on any such
                content, goods or services available on or through any such
                websites or services.
              </p>
              <p>
                5.3 We only provide links to external websites as a convenience,
                and the inclusion of such a link to external websites do not
                imply our endorsement of those websites. You acknowledge and
                agree that when you access other websites on the Internet, you
                do so at your own risk.
              </p>
              <p>
                5.4 We strongly advise you to read the terms and conditions and
                privacy policies of any third-party websites or services that
                you visit.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">6. Termination</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                We may terminate or suspend your account immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if you breach the Terms.
              </p>
              <p>
                6.1 Upon termination, your right to use the Service will
                immediately cease. If you wish to terminate your account, you
                may simply discontinue using the Service.
              </p>
              <p>
                6.2 All provisions of the Terms which by their nature should
                survive termination shall survive termination, including,
                without limitation, ownership provisions, warranty disclaimers,
                indemnity and limitations of liability.
              </p>
              <p>
                6.3 We shall not be liable to you or any third party for any
                claims or damages arising out of any termination or suspension
                or any other actions taken by us in connection therewith.
              </p>
              <p>
                6.4 If applicable law requires us to provide notice of
                termination or cancellation, we may give prior or subsequent
                notice by posting it on the Service or by sending a
                communication to any address (email or otherwise) that we have
                for you in our records.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">7. Indemnification</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                7.1 As a condition of your access to and use of the Service, you
                agree to indemnify us and our successors and assigns for all
                damages, costs, expenses and other liabilities, including but
                not limited to legal fees and expenses, relating to any claim
                arising out of or related to your access to and use of the
                Service or your breach of these Terms and any applicable law or
                the rights of another person or party.
              </p>
              <p>
                7.2 This indemnification section survives the expiration of your
                registration and applies to claims arising both before and after
                the registration ends.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">8. Limitation of liability</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                8.1 You agree that we shall not be liable for any damages
                suffered as a result of you using the Service, copying,
                distributing, or downloading Content from the Service.
              </p>
              <p>
                8.2 In no event shall we be liable for any indirect, punitive,
                special, incidental or consequential damage (including loss of
                business, revenue, profits, use, privacy, data, goodwill or
                other economic advantages) however it arises, whether for breach
                of contract or in tort, even if we have been previously advised
                of the possibility of such damage.
              </p>
              <p>
                8.3 You have sole responsibility for adequate security
                protection and backup of data and/or equipment used in
                connection with your usage of the Service and will not make a
                claim against us for lost data, re-run time, inaccurate
                instruction, delays or lost profits resulting from the use of
                the Service. You must not assign or otherwise dispose of your
                account to any other person. Without limiting the foregoing, in
                no event will our aggregate liability to you exceed, in total,
                the amounts paid by you to us.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">9. Disclaimer</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                9.1 Your use of the Service is at your sole risk. The Service is
                provided on an “AS IS” and “AS AVAILABLE” basis. The Service is
                provided without warranties of any kind, whether express or
                implied, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose,
                non-infringement or course of performance.
              </p>
              <p>
                9.2 GritLife, its subsidiaries, affiliates, and its licensors do
                not warrant that
              </p>
              <p>
                a) the Service will function uninterrupted, secure or available
                at any particular time or location;
              </p>
              <p>b) any errors or defects will be corrected;</p>
              <p>
                c) the Service is free of viruses or other harmful components;
                or
              </p>
              <p>
                d) the results of using the Service will meet your requirements.
              </p>
              <p>
                9.3 This disclaimer of liability applies to any damages or
                injury caused by any failure of performance, error, omission,
                interruption, deletion, defect, delay in operation or
                transmission, computer virus, communication line failure, theft,
                or destruction or unauthorized access or, alteration of or use
                of record in connection with the use or operation of the
                Service, whether for breach of contract, tortious behaviour,
                negligence or any other cause of action.
              </p>
              <p>
                9.4 We make no representations or warranties of any kind,
                express or implied, about the completeness, accuracy,
                reliability, suitability for or availability of the Content
                contained on the Service for any purpose. Any reliance you place
                on such information is therefore strictly at your own risk. We
                disclaim any express or implied warranty representation or
                guarantee as to the effectiveness or profitability of the
                Service or that the operation of our Service will be
                uninterrupted or error-free. We are not liable for the
                consequences of any interruptions or errors in the Service.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">10. Exclusions</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                10.1 Some jurisdictions do not allow the exclusion of certain
                warranties or the exclusion or limitation of liability for
                consequential or incidental damages, so the limitations above
                may not apply to you. Any specific exclusions or limitations
                which may not be permitted in a jurisdiction are formally
                excluded, but only in respect of that jurisdiction and without
                affecting those exclusions or limitations which remain in these
                Terms.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">11. Governing law</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                11.1 These Terms shall be governed and construed in accordance
                with the laws of Queensland, Australia, without regard to its
                conflict of law provisions.
              </p>
              <p>
                11.2 Our failure to enforce any right or provision of these
                Terms will not be considered a waiver of those rights. If any
                provision of these Terms is held to be invalid or unenforceable
                by a court, the remaining provisions of these Terms will remain
                in effect. These Terms constitute the entire agreement between
                us regarding our Service, and supersede and replace any prior
                agreements we might have between us regarding the Service.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">12. Changes</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                12.1 We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is a material, we
                will try to provide at least 30 days notice prior to any new
                terms taking effect.
              </p>
              <p>
                12.2 It is your sole responsibility to periodically check these
                Terms for any changes. If you do not agree with any of the
                changes to these Terms, it is your sole responsibility to stop
                using the Service. Your continued use of the Service will be
                deemed as your acceptance thereof.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">13. Property Rights</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                13.1 GritLife hereby grants to users a limited, non-exclusive,
                non-transferable, royalty-free license, exercisable solely
                during the term of this Agreement, to use GritLife technology,
                products and services solely for the purpose of accessing and
                using the Services.
              </p>
              <p>
                13.2 Users may not use GritLife’s technology for any purpose
                other than accessing and using the Services within its intended
                use. Except for the rights expressly granted above, this
                Agreement does not transfer from GritLife to user any GritLife
                technology, and all rights, titles and interests in and to any
                GritLife technology shall remain solely with GritLife.
              </p>
              <p>
                13.3 Users shall not, directly or indirectly, reverse engineer,
                decompile, disassemble or otherwise attempt to derive source
                code or other trade secrets from the GritLife.
              </p>
              <p>
                13.4 GritLife owns all rights, title and interest in and to the
                Services and GritLife’s trade names, trademarks, service marks,
                inventions, copyrights, trade secrets, patents, know-how and
                other intellectual property rights relating to the design,
                function, marketing, promotion, sale and provision of the
                Services and the related hardware, software and systems
                (“Marks”). Nothing in this Agreement constitutes a license to
                the user to use or resell the Marks.
              </p>
            </Col>
          </Row>

          <h2 className="text-uppercase my-5">14. Contact us</h2>

          <Row>
            <Col md={{ span: 4, offset: 6 }}>
              <p>
                If you have any questions about this Terms of Service, You can
                contact us:
              </p>
              <p>
                By email:{" "}
                <a href="mailto:support@gritlife.app">support@gritlife.app</a>
              </p>
              <p>
                By visiting this page on our website:{" "}
                <a href="https://gritlife.app">www.gritlife.app</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default TermsPage
